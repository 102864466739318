import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import CustomNavTitle from "../components/CustomNavTitle"

import { theme } from "../styles/theme"
import { version } from "../../package.json"

const About = ({ location }) => {
  return (
    <Layout>
      <SEO title="About" />

      <CustomNavTitle title="About" destination="/" location={location} />

      <article css={theme.components.row} className="theme">
        <section>
          <h3 css={theme.components.title}>About the content</h3>
          <p>
            The guidance for transfusion provided in this website is based on:
          </p>
          <ul>
            <li>
              BSH administration, management of transfusion reactions,
              irradiated blood components, and fresh frozen plasma and
              cryoprecipitate guidelines.
            </li>

            <li>SaBTO consent, CMV and irradiation guidance.</li>

            <li>NHSBT compatibility and component selection guidance.</li>

            <li>SHOT recommendations.</li>

            <li>Supreme court ruling on consent.</li>
          </ul>
          <p>
            These are current guidelines and may change depending on new
            evidence.
          </p>
        </section>

        <section>
          <h3 css={theme.components.title}>About the website</h3>
          <p>
            This website was created by NHSBT PBM team, website design and
            production by HMA.
          </p>
          <p>Version Number: {version}</p>
        </section>

        <section>
          <h3 css={theme.components.title}>Contact us</h3>
          <address>
            Customer Service
            <br />
            NHS Blood and Transplant,
            <br />
            Part Academic Block, Level 2,
            <br />
            John Radcliffe Hospital,
            <br />
            Headley Way, Headington,
            <br />
            Oxford OX3 9BQ
          </address>
          <p>
            Email:{" "}
            <a href="mailto:PBM.Team@nhsbt.nhs.uk">
              PBM.Team@nhsbt.nhs.uk
            </a>
          </p>
        </section>

        <section>
          <h3 css={theme.components.title}>References</h3>
          <ul>
            <li>
              <a href="https://b-s-h.org.uk/guidelines/guidelines/administration-of-blood-components/">
                https://b-s-h.org.uk/guidelines/guidelines/administration-of-blood-components/
              </a>
            </li>
            <li>
              <a href="https://www.transfusionguidelines.org/transfusion-practice/consent-for-blood-transfusion">
                https://www.transfusionguidelines.org/transfusion-practice/consent-for-blood-transfusion
              </a>
            </li>
            <li>
              <a href="https://www.supremecourt.uk/cases/docs/uksc-2013-0136-judgment.pdf">
                https://www.supremecourt.uk/cases/docs/uksc-2013-0136-judgment.pdf
              </a>
            </li>
            <li>
              <a href="https://www.shotuk.org/resources/current-resources/">
                https://www.shotuk.org/resources/current-resources/
              </a>
            </li>
            <li>
              <a href="https://nhsbtdbe.blob.core.windows.net/umbraco-assets-corp/16494/spn223v10.pdf">
                https://nhsbtdbe.blob.core.windows.net/umbraco-assets-corp/16494/spn223v10.pdf
              </a>
            </li>
            <li>
              <a href="https://b-s-h.org.uk/guidelines/guidelines/spectrum-of-fresh-frozen-plasma-and-cryoprecipitate-products/">
                https://b-s-h.org.uk/guidelines/guidelines/spectrum-of-fresh-frozen-plasma-and-cryoprecipitate-products/
              </a>
            </li>
            <li>
              <a href="https://www.gov.uk/government/publications/sabto-report-of-the-cytomegalovirus-steering-group">
                https://www.gov.uk/government/publications/sabto-report-of-the-cytomegalovirus-steering-group
              </a>
            </li>
            <li>
              <a href="https://b-s-h.org.uk/guidelines/guidelines/guidelines-on-the-use-of-irradiated-blood-components/">https://b-s-h.org.uk/guidelines/guidelines/guidelines-on-the-use-of-irradiated-blood-components/</a>
            </li>
            <li>
              <a href="https://b-s-h.org.uk/guidelines/guidelines/investigation-and-management-of-acute-transfusion-reactions/">
                https://b-s-h.org.uk/guidelines/guidelines/investigation-and-management-of-acute-transfusion-reactions/
                pg. 145
              </a>
            </li>
          </ul>
        </section>

        <section>
          <h3 css={theme.components.title}>
            Abbreviations (not previously explained)
          </h3>
          <p>
            <strong>ATG:</strong> anti-thymocyte globulin
          </p>
          <p>
            <strong>CCP:</strong> Coronavirus convalescent plasma
          </p>
          <p>
            <strong>CMV:</strong> cytomegalovirus
          </p>
          <p>
            <strong>FBC:</strong> full blood count
          </p>
          <p>
            <strong>IgA:</strong> immunoglobulin A
          </p>
          <p>
            <strong>LDH:</strong> lactate dehydrogenase
          </p>
          <p>
            <strong>NHSBT:</strong> NHS Blood and Transplant
          </p>
          <p>
            <strong>PBM:</strong> Patient Blood Management
          </p>
          <p>
            <strong>SaBTO:</strong> Advisory Committee on the Safety of Blood,
            Tissues and Organs
          </p>
          <p>
            <strong>SAGM:</strong> Additive solution containing Saline, Adenine,
            Glucose and Manitol
          </p>
          <p>
            <strong>SHOT:</strong> Serious Hazards of Transfusion
          </p>
        </section>
      </article>
    </Layout>
  )
}

export default About
